export const domainList = ['직접 입력', 'naver.com', 'daum.net', 'gmail.com']

export const careerList = [
  '신입',
  '1년',
  '2년',
  '3년',
  '4년',
  '5년',
  '6년',
  '7년',
  '8년',
  '9년',
  '10년',
]
